@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}
@function url-encode($string) {
    //https://gist.github.com/B-iggy/14da053d2cebf92e1930
    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        " ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D"
        );
    $new: $string;
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }
    @return $new;
}
@function svg( $lines... ){

    @return url('data:image/svg+xml;charset=utf8,#{url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1">#{join($lines, (), space)}</svg>')}');

}
@function svg-flexible( $lines... ){

    @return url('data:image/svg+xml;charset=utf8,#{url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1" preserveAspectRatio="none">#{join($lines, (), space)}</svg>')}');

}